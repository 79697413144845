.centered {
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;
}

.trustSection {
  margin-top: 24px;
}

.mainContent {
  padding-top: 40px;
}

.logoGrid {
  display: grid;
  width: 100%;
  row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 860px) {
  .logoGrid {
    grid-template-columns: 1fr 1fr;
  }
}

.logoImage {
  width: 100%;
  opacity: 0;
  transform: scale(0.5);
  object-fit: contain;
  object-position: center center;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

@media (max-width: 860px) {
  .logoImage {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

.logoContainer {
  height: 72px;
  display: flex;
  align-items: center;
}

.visible {
  opacity: 0.65;
  transform: scale(1);
}
