.root {
  position: relative;
  overflow: hidden;
  z-index: 2;
  padding-top: 64px;
  box-sizing: border-box;
}

.head {
  padding: 0 40px;
  @media (max-width: 880px) {
    text-align: left;
  }
}

.grid {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0px;
  row-gap: 24px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
}

.card {
  padding: 24px;
  border-top: 1px solid var(--neutral-color-400);
  border-bottom: 1px solid var(--neutral-color-400);
  text-align: left;

  &:nth-child(even) {
    border-left: 1px solid var(--neutral-color-400);
  }
}

.avatar {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  box-shadow: var(--shadow-2);
  border: 1px solid var(--border-color);
}

.headCard {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.avatarName {
  font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.005em;
  line-height: 1.2814285714;
  color: var(--neutral-color-800);
}

.avatarTitle {
  font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.005em;
  line-height: 1.2814285714;
  color: var(--neutral-color-800);
}

.description {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  color: var(--neutral-color-600);
  font-family: 'SF Pro Text';
  margin-bottom: 24px;
  text-align: center;
  @media (max-width: 880px) {
    text-align: left;
  }
}

.cardDescription {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  color: var(--neutral-color-600);
  font-family: 'SF Pro Text';
  margin-bottom: 24px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 0;
  font-family: 'SF Pro Display';
  text-align: center;
  @media (max-width: 880px) {
    text-align: left;
  }
}

.subtitle {
  font-size: 32px;
  margin-top: 8px;
  margin-bottom: 20px;
  font-family: 'SF Pro Display';
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: var(--neutral-color-800);
  line-height: 1.45;
  @media (max-width: 1200px) {
    font-size: 36px;
  }

  @media (max-width: 860px) {
    font-size: 32px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
  }

  @media (max-width: 500px) {
    font-size: 24px;
  }

  @media (max-width: 400px) {
    font-size: 20px;
  }
  @media (max-width: 880px) {
    text-align: left;
  }
}

.titleGradient {
  background: linear-gradient(90deg, var(--pink-solid-color) 0%, var(--orange-solid-color) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.codeBox {
  font-size: 16px;
  font-family: var(--font-family-code);
  border-radius: 4px;
  overflow-x: auto;
  white-space: pre-wrap;
  margin-bottom: 24px;
  line-height: 1.6;
  color: var(--neutral-color-800);
  height: 104px;
}
